import { Meta, Title } from "@solidjs/meta";
import { RouteSectionProps } from "@solidjs/router";
import { WorkspaceProvider } from "~/context/WorkspaceProvider";

export default function PlaygroundLayout(props: RouteSectionProps) {
  return (
    <>
      <>
        <Title>Workspaces - JulepAI</Title>
        <Meta name="description" content="JulepAI playground." />
        <Meta name="viewport" content="width=device-width, initial-scale=1" />
      </>
      <main class="relative flex max-h-screen w-screen flex-col">
        <div class="flex flex-row w-full">
          <div class="w-full">{/* <WorkspaceProvider>{props.children}</WorkspaceProvider> */}</div>
        </div>
      </main>
    </>
  );
}
